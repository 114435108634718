import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

const Error = () => {
  return (
    <Layout>
      <Seo title="Page not found" />
      <main style={{ height: "70vh" }}>
        <h3 style={{ textAlign: "center" }}>Sorry</h3>
        <h1 style={{ textAlign: "center" }}>This page is not found.</h1>
      </main>
    </Layout>
  )
}

export default Error
